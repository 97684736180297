function consoleLogError(e) {
  (console.error || console.log).call(console, `Error: ${e.stack || e.message || e}`);
}

function logErrorToNewRelic(error) {
  // NewRelic script can be blocked by AdBlock etc
  if (!window.NREUM) return;
  if (!error) {
    window.NREUM.noticeError({ message: "Null Error - Fix This" });
  } else if (!error.message && error.sagaStack) {
    window.NREUM.noticeError({ ...error, ...{ message: error.sagaStack } });
  } else {
    window.NREUM.noticeError(error);
  }
}

export const rejectionMiddleware = () => (next) => (action) => {
  if (action.type && action.type.includes("rejected")) handleError(action.error);
  return next(action);
};

//Errors reported in our code should use this to report them
export function handleError(error) {
  consoleLogError(error);
  logErrorToNewRelic(error);
}

// All errors should otherwise be caught by new relic
// which uses this to ignore certain errors.
export function NewRelicErrorHandler() {
  // return true to skip error reporting
  return false;
}
