import * as parseurl from "parseurl";
import * as queryString from "querystring";
import { handleError } from "services/errorService";
import { routes } from "src/routes";
import { globalObject } from "./globalObject";
import { vendor } from "./vendorHelper";

/**
 * Tracking helper should not be referenced from other files in the application. Use globalObject.trackingHelper instead.
 * Tracking helper is transpiled with a separate webpack pipeline, because we need it as a separate file loaded before AdobeAnalytics script.
 */

const getPageName = (pageUrl) => {
  if (/\/orders\/.+/.test(pageUrl)) return "Account: Order Detail";

  if (/\/online-products\/.+/.test(pageUrl)) return "Account: Widget Detail";

  if (/\/address\/.+/.test(pageUrl)) return "Account: Edit Address";

  switch (pageUrl) {
    case routes.home:
      return "Account: Overview";
    case routes.orders:
      return "Account: Orders";
    case routes.invoices:
      return "Account: B2B Invoices";
    case routes.ftpIntegration:
      return "Account: B2B Integration";
    case routes.savedProjects:
      return "Account: Saved Projects";
    case routes.photos:
      return "Account: Photos";
    case routes.albums:
      return "Account: Albums";
    case routes.personalDetails:
      return "Account: Details";
    case routes.changeEmail:
      return "Account: Email";
    case routes.addAddress:
      return "Account: New Address";
    case routes.allAddresses:
      return "Account: Address Overview";
    case routes.addAddressB2b:
      return "Account: New Address";
    case routes.paymentMethod:
      return "Account: Payment Method";
    case routes.settings:
      return "Account: Settings";
    case routes.doubleOptInConfirmationSuccess:
      return "Other: Newsletter subscription success (double opt-in)";
    case routes.doubleOptInConfirmationError:
      return "Other: Newsletter subscription error (double opt-in)";
    case routes.widgets:
      return "Account: Widget Overview";
    default:
      return "";
  }
};

const getCurrency = (vendor) => {
  if (!globalObject.channels) return null;

  const channel = globalObject.channels.find((c) => c.ID === vendor);
  return channel && channel.Currency ? channel.Currency.ID : null;
};

/**
 * Returns the original route where the user wanted to navigate.
 * This route might be different from the current route on the first navigation after authentication.
 */
const getAuthOriginalRoute = () => {
  const queryParams = queryString.parse(window.location.search.substring(1));
  const isInAuthCallback = queryParams.code && queryParams.state && queryParams.session_state;
  if (isInAuthCallback) {
    try {
      const authState = JSON.parse(localStorage.getItem(queryParams.state)); // Authentication library state
      return authState && parseurl({ url: authState.original_uri }).pathname;
    } catch (e) {
      handleError({ ...e, ...{ message: "Auth / Tracking Error: " + e.message } });
    }
  }

  return null;
};

const initializeDataObject = () => {
  if (window.AA_data === undefined) window.AA_data = {};

  window.AA_data["currency-code"] = getCurrency(vendor);
  window.AA_data["google-analytics-ua"] = globalObject.AnalyticsId;
  window.AA_data["menu-title"] = "My Account";
  window.AA_data["page-category"] = "Account";
  window.AA_data["page-subcategory"] = "";
  window.AA_data["platform-version"] = "new";
  window.AA_data["site-section"] = "Account";
  window.AA_data["vendor-domain-name"] = vendor;
};

const trackNavigation = (nextPageUrl) => {
  if (window.AA_data === undefined) window.AA_data = {};
  const pageUrl = getAuthOriginalRoute() || nextPageUrl;

  window.AA_data["page-name"] = getPageName(pageUrl);
  window.AA_data["url-without-domain"] = nextPageUrl;
  window.AA_data["url-without-domain-global"] = nextPageUrl;
};

const trackAccountSubject = (userId) => {
  if (window.AA_data === undefined) window.AA_data = {};
  window.AA_data["account-subject"] = userId;
};

const trackEvent = (eventName) => {
  if (!window._satellite) return;

  window._satellite.track("custom prop tracking", { countingProp: eventName });
};

initializeDataObject();
trackNavigation(window.location.pathname);
globalObject.trackingHelper = {
  trackNavigation,
  trackAccountSubject,
  trackEvent
};
